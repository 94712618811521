


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  background: linear-gradient(to right, grey, #121212);
  color: #E0E0E0;
  font-size: larger;
}

.banner {
  background-color: #282c34;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.flex-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;
}

.flex-grid-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.flex-grid-item {
  flex-basis: 30%;
  height: fit-content;
}

.image-item {
  border: 5px solid '#282c34';
  border-radius: 10px;
}

.nav-tab-active {
  background-color: rgba(248,220,157, 0.5);
  flex-grow: 1;
  flex-basis: 50%;
  text-align: center;
}
.nav-tab-inactive {
  flex-grow: 1;
  flex-basis: 50%;
  text-align: center;
}

.nav-tab-container {
  justify-content: center;
  display: flex;
  gap: 10px;
}

.store-item-name{
  color: white;
}

a:link, a:visited {
  color: white;
}

.store-name{
  background-color: #282c34;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  min-width: 210px;
  align-items: center;
  width: 100%;
  min-height: 50px;
  min-width: 332px;
}

.privacy-policy-container {
  padding: 50px;
}

.privacy-policy-header {
  font-weight: 800;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
